import React, { useContext } from 'react'
import styled from 'styled-components'
import { ModalContext } from 'components/Modal'

const Card = styled.div`
  background-color: white;
  padding: 8px;
  color: black;
  > p {
    word-break: keep-all;
    :first-child {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  > a {
    margin: 3px;
  }
`

export default function StoryblokModalDealerCard () {
  const { dealer } = useContext(ModalContext)
  return (
    <Card>
      <p>{dealer?.companyName}</p>
      <p>
        {dealer?.address.street} {dealer?.address.nr}
      </p>
      <p>
        {dealer?.address.postCode} {dealer?.address.city}
      </p>
    </Card>
  )
}
